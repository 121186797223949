import toast from "react-hot-toast"
import { appAPI } from "../api/service"
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";



export const OrderModal = ({openOrder,updateOrder, getStatusFinish, getStatus, setImage, setModalActive, setOpenOrder}) => {

    const [commnet, setComment] = useState(null)

    const [code, setCode] = useState("")



    useEffect(() => {
      if(!openOrder) return 
      if(!openOrder.media) {
        console.log(openOrder )
        async function fetchData() {
          const res = await appAPI.getMediaFiles({
            "action": "filemedia",
            "data": {
              "id": Number(openOrder?.id)
            }
          })
          if(res.error) return setOpenOrder({...openOrder, media: true})
          setOpenOrder({...openOrder, media: res})
          return console.log(res)
        } 
        fetchData()
      }
      
    }, [openOrder])


  useEffect(() => {
      if(!openOrder || commnet !== null) return
      async function fetchDataComment() {
        const res = await appAPI.getComment({
          "action": "getcomment",
          "data": {
            "id": openOrder.id,
            "chat_id": openOrder.chat_id
          }
        })
        if(res.error) return toast.error(res.error)
        setComment(res.comment)
      }
      fetchDataComment()  
  }, [openOrder])

    const [check, setCheck] = useState(null)


    const handleCommentSend = async () => {
        if(!commnet) return 
        const res = await appAPI.editComment({
          "action": "editcomment",
          "data": {
            "id": openOrder?.id,
            "chat_id": openOrder?.chat_id,
            "comment": commnet
          }
        })
        if(res.error) return toast.error(res.error)
        
        return toast.success(res.message)
      }

  const handleCheckFileSelect = (event) => {
    const file = event.target.files[0];
    convertFileToBase64(file, setCheck);
  };

  const convertFileToBase64 = (file, setState) => {
    const reader = new FileReader();
    reader.onloadstart = () => console.log("Starting to read the file...");

    reader.onloadend = () => {
      if (reader.error) {
        console.log("Error reading file:", reader.error);
      } else {
        setState(reader.result);
      }
    };

    reader.readAsDataURL(file);
  };

  const handleDownload = (data) => {
    const link = document.createElement("a");
    link.href = data;
    link.download = "Data";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

    return (
    
            <>
                <div className="h-0 flex-1 overflow-y-auto">
                  <div className="bg-indigo-700 px-4 py-6 sm:px-6">
                    <div className="flex items-center justify-between">
                      <div className="text-base font-semibold leading-6 text-white">
                        
                      </div>
                      <div className="ml-3 flex h-2 items-center">
                        <button
                          type="button"
                          className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                          onClick={() => {
                            setModalActive(false)
                            setCheck(null)
                            setTimeout(() => {
                              setComment("")
                              setOpenOrder(null)
                            }, 500)
                          }}
                        >
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-1 flex-col justify-between">
                  <div className="flex flex-shrink-0 justify-between space-x-0">
                  {openOrder?.media && (Array.isArray(openOrder?.media)) && <>
                    {openOrder.media.map((img, index) => {
                      if (img.media.length < 250) return null;

                      {/*if(openOrder.media.length >= 1 && openOrder.type == 4 && !openOrder.media[index]) {
                        <div key={index} className="flex-grow h-40 w-full bg-white text-3xl font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                          WAIT
                        </div>
                      } */}

                      return (
                        <div key={index} className="flex-grow h-40 w-full bg-white text-3xl font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                          <img
                            onClick={() => setImage(`data:${img.type};base64,${img.media}`)}
                            src={String(img).includes("https") ? img : `data:${img.type};base64,${img.media}`}
                            className="h-full w-full object-cover rounded-md"
                          />
                        </div>
                      );
                    })}
                  </>}
                  {/*(openOrder?.type != 1 && openOrder?.type != -1) ? openOrder?.user_requisites && (Array.isArray(openOrder.user_requisites) ? (
                    openOrder.user_requisites.map((img, index) => {
                      if (img.length < 250) return null;

                      if(openOrder.user_requisites.length >= 1 && openOrder.type == 4 && !openOrder.user_requisites[index]) {
                        <div key={index} className="flex-grow h-40 w-full bg-white text-3xl font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                          WAIT QR
                        </div>
                      }
                      return (
                        <div key={index} className="flex-grow h-40 w-full bg-white text-3xl font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                          <img
                            onClick={() => setImage(`data:image/png;base64,${img}`)}
                            src={String(img).includes("https") ? img : `data:image/png;base64,${img}`}
                            className="h-full w-full object-cover rounded-md"
                          />
                        </div>
                      );
                    })
                  ) : (
                    <>
                    </>
                  )) : 
                  (!openOrder?.media ) ? <div key={"media"} className="flex-grow h-40 w-full bg-white text-3xl font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Loading...</div> : <div key={"media"} className="flex-grow h-40 w-full bg-white text-3xl font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                      {openOrder?.media === true ? "Not found" : <img
                        onClick={() => setImage(`data:${openOrder?.media.type};base64,`+openOrder?.media.media)}
                        src={`data:${openOrder?.media.type};base64,`+openOrder?.media.media}
                        className="h-full w-full object-cover rounded-md"
                      />}
                      
                    </div>
                  */}
                  </div>

                  

                      
                    <div className="divide-y divide-gray-200 px-4 sm:px-3">
                      
                    
                      <div className="space-y-3 pb-5 pt-2">
                      
                      {openOrder?.media && (Array.isArray(openOrder?.media)) && <>
                        {openOrder.media.map((img, index) => {
                          if (!img.type.includes("application")) return null;
                          return (
                            <button
                              onClick={async () => handleDownload(`data:${img.type};base64,${img.media}`)}
                              className="flex-grow rounded-md w-full bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              Скачать файл 
                            </button>
                          );
                        })}
                      </>}

                      

                        <div className="break-all">№ {openOrder?.id}</div>
                        <div className="break-all">Дата: {openOrder?.transaction_date
                              ? new Date(openOrder.transaction_date * 1000).toLocaleString()
                              : 'Не указано'}</div>
                        <div className="break-all">Логин юзера: {openOrder?.chat_id || 'Не указано'}</div>
                        <div className="break-all">Курс: {openOrder?.stable_kurs || 'Не указано'}</div>

                        <div className="break-all">Тип транзакции: {getStatus(openOrder?.finish, openOrder?.type)}</div>

                        <div className="break-all">Отдает: {openOrder?.qiwi_amount} {openOrder?.qiwi_number}</div>
                        <div className="break-all">Получает: {openOrder?.btc_amount} {openOrder?.btc_tx}</div>
                        <div className="break-all">Реферал: {openOrder?.qiwi_amount || 'Не указано'}</div>
                        {openOrder?.media && (Array.isArray(openOrder?.media)) && <>
                          {openOrder.media.map((img, index) => {
                            if (img.media.length > 250) return 
                            return (
                              <div className="break-all">Реквизиты: {img?.media || 'Не указано'}</div>
                            );
                          })}
                        </>}
                        {/*openOrder?.user_requisites && (Array.isArray(openOrder.user_requisites)) ? openOrder?.user_requisites.map((req) => {
                          if(req.length > 100) return 
                          return (
                            <div className="break-all">Реквизиты: {req || 'Не указано'}</div>
                          )
                        }): <div className="break-all">Реквизиты: {openOrder?.user_requisites || 'Не указано'}</div>*/}
                        <div className="break-all">Статус: {getStatusFinish(openOrder?.finish, openOrder?.type)}</div>
                  
                        <div>
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            Комментарий
                          </label>
                          <div className="mt-2">
                            <input
                              value={commnet == null ? "" : commnet}
                              onChange={(e) => setComment(e.target.value)}
                              type="text"
                              name="project-name"
                              id="project-name"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>

                        <button
                          onClick={async () => handleCommentSend}
                          className="flex-grow rounded-md w-full bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Добавить коментарий
                        </button>

                        {openOrder?.type == 4 && <><div>
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            Код для пользователя
                          </label>
                          <div className="mt-2">
                            <input
                              value={code}
                              onChange={(e) => setCode(e.target.value)}
                              type="text"
                              name="project-name"
                              id="project-name"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>

                        <button
                          onClick={async () => {
                            if(!commnet) return 
                            const res = await appAPI.editComment({
                              "action": "editcomment",
                              "data": {
                                "id": openOrder?.id,
                                "chat_id": openOrder?.chat_id,
                                "code": code
                              }
                            })
                            if(res.error) return toast.error(res.error)
                            
                            return toast.success(res.message)
                          }}
                          className="flex-grow rounded-md w-full bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Отправить код
                        </button></>}
                      </div>
                    </div>
                  </div>
                </div>
                {(openOrder?.type == "5" && openOrder?.finish !== "1") && <label className='doc__send'>
                    <img src="/doc.svg" />
                    <input type="file" accept="image/*" style={{ display: "none" }} onChange={handleCheckFileSelect} />
                  </label>}
                <div className="flex flex-shrink-0 justify-between space-x-0">
                {((openOrder?.finish !== '1' || (openOrder?.finish != '1' && openOrder?.type != "0") ) && (openOrder?.finish == '1' ? openOrder?.type != "0" : openOrder?.finish == "0" ?  openOrder?.type != "0"  : true) )&& <>
                {(openOrder?.type == "5" && openOrder?.finish !== "1" && !openOrder?.decomentSend) ? <button
                  type="submit"
                  disabled
                  onClick={() => updateOrder("finish")}
                  className="flex-grow h-20 w-full bg-gray-600 px-3 py-2 text-4xl font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  ✓
                </button> :
                <button
                  type="submit"
                  onClick={() => updateOrder("finish")}
                  className="flex-grow h-20 w-full bg-indigo-600 px-3 py-2 text-4xl font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  ✓
                </button>
                
                }
                <button
                  type="button"
                  className="flex-grow h-20 w-full  bg-white px-3 py-2 text-3xl font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onClick={() => updateOrder("cancel")}
                >
                   ❌
                </button>
                <button
                  type="button"
                  className="flex-grow h-20 w-full  bg-white px-3 py-2 text-3xl font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onClick={() => updateOrder("pending")}
                >
                  ?
                </button></>}
              </div>

              
              </>
    )
}