import {Navigate, Route, Routes} from "react-router-dom"
import Main from "./pages/main";
import Auth from "./pages/auth";
import Settings from "./pages/settings";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { appAPI } from "./api/service";
import Loader from "./componenets/loader";
import Users from "./pages/users";

function App() {

  const [userInfo,setUserInfo] = useState(null)

  async function getUser() {
    try {
      const res = await appAPI.getUsers(1, 10)
      if(res.error) {
        return setUserInfo(false)
      } else {
        setUserInfo(res)
      }
    } catch (e) {
      console.log(e)
      setUserInfo(false)
      return toast.error("Ошибка аутентификации пользователя")
    }
    
  }

  useEffect(() => {
    getUser()
  },[])


  console.log(userInfo)

  if(userInfo === null) return <Loader />
  return (
    <div className="app">
        <Routes>
          <Route path="/" element={<Main userInfo={userInfo}/>}/>
          <Route path="/users" element={<Users userInfo={userInfo}/>}/>
          <Route path="/settings" element={<Settings userInfo={userInfo}/>}/>
          <Route path="/auth" element={<Auth userInfo={userInfo} getUser={getUser}/>}/>
          <Route path="/*" element={<Navigate to="/"/>}/>
        </Routes>
    </div>
  );
}

export default App;
