import Layout from '../layouts/Layout'
import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import toast from 'react-hot-toast'
import { appAPI } from '../api/service'
import { Navigate } from 'react-router-dom'
import Loader from '../componenets/loader'

const Settings = ({userInfo}) => {

    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [navigation, setNavigation] = useState([
        { name: 'Общие параметры', key_datas: ['blockio_btc', 
      'blockio_key', 
      'timer', 
      'percent'], current: false, dataForRender: [
            /*{title: "", type: "input", text: "", fromBackend: "url_as", toBackend: "new_url_as"},
            {title: "1",type: "textarea", text: "", fromBackend: "url_as1", toBackend: "new_url_as"},
            {title: "2",type: "input", text: "", fromBackend: "url_as2", toBackend: "new_url_as"},*/
        ]},
        { name: 'Модули бота', key_datas: ["modules_status"], current: false, dataForRender: []},
        { name: 'Наличка банки', key_datas: ["cash_banks_status"], current: false, dataForRender: []},
        { name: '💱 Обмен',key_datas: [ 'manual_change_thb', 
            'manual_change_rub', 
            'manual_change_kzt', 
            'manual_change_idr', 
            'manual_change_mmk', 
            'manual_change_vnd', 
            'change_kurs_rub', 
            'change_kurs_thb', 
            'change_kurs_kzt', 
            'change_kurs_idr', 
            'change_kurs_mmk', 
            'change_kurs_vnd', 
            'requisites_krungsri', 
            'requisites_bangkok_bank', 
            'requisites_kasikornbank', 
            'requisites_tinkoff', 
            'requisites_sber', 
            'requisites_sbp', 
            'requisites_halyk_bank', 
            'requisites_kaspi_kz', 
            'requisites_bcc', 
            'requisites_permata_bank', 
            'requisites_may_bank', 
            'requisites_mmk_change_bank', 
            'requisites_vnd_change_bank', 
            'min_rub_change', 
            'max_rub_change', 
            'min_thb_change', 
            'max_thb_change', 
            'min_kzt_change', 
            'max_kzt_change', 
            'min_idr_change', 
            'max_idr_change', 
            'min_mmk_change', 
            'max_mmk_change', 
            'min_vnd_change', 
            'max_vnd_change'] ,  current: false, dataForRender: [] },
        { name: '🔘 Scan PAY',key_datas: ['manual_scan_usdt', 
      'manual_scan_rub', 
      'manual_scan_usdt_vnd', 
      'manual_scan_rub_vnd', 
      'manual_scan_usdt_idr', 
      'manual_scan_rub_idr', 
      'scan_kurs_usdt_thb', 
      'scan_kurs_rub_thb', 
      'scan_kurs_usdt_vnd', 
      'scan_kurs_rub_vnd', 
      'scan_kurs_usdt_idr', 
      'scan_kurs_rub_idr', 
      'requisites_scan_rub', 
      'requisites_scan_usdt', 
      'requisites_scan_vnd_rub', 
      'requisites_scan_vnd_usdt', 
      'requisites_scan_idr_rub', 
      'requisites_scan_idr_usdt', 
      'min_thb_scan',
      'max_thb_scan',
      'min_vnd_scan',
      'max_vnd_scan',
      'min_idr_scan',
      'max_idr_scan'] ,  current: false, dataForRender: [] },
      { name: '💵 НАЛИЧНЫЕ', key_datas: ['manual_cash_thb', 
      'manual_cash_rub', 
      'manual_cash_usdt', 
      'cash_kurs_thb_rub', 
      'cash_kurs_rub_thb', 
      'cash_kurs_usdt_rub', 
      'cash_kurs_usdt_thb', 
      'cash_kurs_usdt_idr', 
      'cash_kurs_idr_rub', 
      'cash_kurs_kzt_rub', 
      'cash_kurs_usdt_kzt', 
      'cash_kurs_usdt_mmk', 
      'cash_kurs_mmk_rub', 
      'requisites_cash_rub_thb', 
      'requisites_cash_rub_idr', 
      'requisites_cash_rub_kzt', 
      'requisites_cash_rub_mmk', 
      'requisites_cash_rub',
      'requisites_cash_thb', 
      'requisites_cash_usdt_thb', 
      'requisites_cash_usdt_kzt', 
      'requisites_cash_usdt_idr', 
      'requisites_cash_usdt_mmk', 
      'requisites_cash_usdt', 
      'requisites_cash_idr', 
      'requisites_cash_kzt', 
      'requisites_cash_mmk',
      'min_rub_cash', 
      'max_rub_cash', 
      'min_thb_cash', 
      'max_thb_cash', 
      'min_kzt_cash', 
      'max_kzt_cash', 
      'min_idr_cash', 
      'max_idr_cash', 
      'min_mmk_cash',
      'max_mmk_cash'], current: false, dataForRender: [] },
      { name: '🟢ПОКУПКУ USDT:', key_datas: ['manual_buy_rub_btc', 
    'manual_buy_thb_btc', 
    'buy_perc_rub_btc',  
    'buy_perc_thb_btc', 
    'requisites_buy_rub_btc', 
    'requisites_buy_thb_btc', 
    'min_limit_buy_rub_btc', 
    'max_limit_buy_rub_btc', 
    'min_limit_buy_thb_btc', 
    'max_limit_buy_thb_btc',
      'manual_buy_rub', 
      'manual_buy_thb', 
      'buy_kurs_rub', 
      'buy_kurs_thb', 
      'requisites_buy_rub', 
      'requisites_buy_thb', 
      'requisites_buy_rub_bep20', 
      'requisites_buy_thb_bep20', 
      'min_limit_buy_usdt', 
      'max_limit_buy_usdt'] ,current: false, dataForRender: [] },
      { name: '🔴ПРОДАЖУ USDT', key_datas: ['manual_sell_rub_btc', 
    'manual_sell_thb_btc', 
    'sell_perc_rub_btc', 
    'sell_perc_thb_btc',
    'requisites_sell_rub_btc',
    'requisites_sell_thb_btc',
    'min_limit_sell_rub_btc',
    'max_limit_sell_rub_btc',
    'min_limit_sell_thb_btc',
    'max_limit_sell_thb_btc'] ,current: false, dataForRender: [] },
      { name: '💳 Карта PYYPL (Пополнение)', key_datas: ['buy_pyypl_kurs_rub', 
      'manual_pyypl_buy_rub', 
      'min_rub_buy_pyypl', 
      'max_rub_buy_pyypl'] ,current: false, dataForRender: [] },
      { name: '💳 Карта PYYPL (Вывод)', key_datas: [ 'sell_pyypl_kurs_usdt', 
        'manual_pyypl_sell_usdt', 
        'min_usdt_sell_pyypl', 
        'max_usdt_sell_pyypl', 
        'requisites_pyypl_usdt'] ,current: false, dataForRender: [] },




    /*        { name: 'Упр.нал.банк', key_datas: ["modules_status"] , current: false },
        { name: 'USD: Продажа', current: false },
        { name: 'USD: Покупка', current: false },
        { name: 'BTC: Продажа', current: false },
        { name: 'BTC: Покупка', current: false },
        { name: 'ETH: Продажа', current: false },
        { name: 'ETH: Покупка', current: false },
        { name: 'PYYPL: Пополнение', current: false },
        { name: 'PYYPL: Вывод', current: false },
        { name: 'Наличные', key_word: "cash", current: false },
        { name: 'Обмен', current: false },
        { name: 'Scan Pay', current: false }, */
    ])
    const [activeSection, setActiveSection] = useState(navigation[0])
    const [serverSettings, setServerSettings] = useState(null)
    const [loading, setLoading] = useState(false)

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    useEffect(() => {
        setSidebarOpen(false);
        setNavigation(prev => prev.map(el => {
            if(el.name === activeSection.name) {
                return {...el, current: true};
            }
            if(el.current === true) {
                return {...el, current: false};
            }
            return el;
        }));

        setUnsavedChanges({});
    }, [activeSection]);

    const handleSaveChanges = async () => {
        try {
            // Example API call to save changes
            const data = {
                action: `editsettings`,
                settings: {}
            }
            unsavedChanges.map(item => data.settings[item.data] = item.pkey)
            const res = await appAPI.saveSettings(data);
            if(res.error) return toast.error(res.error)
            toast.success("Изменения сохранены");
            
            setNavigation(prev => prev.map(item => {
                console.log(item)
                if (item.current) {
                    return {
                        ...item,
                        dataForRender: item.dataForRender.map(i => {
                            if(unsavedChanges.find(item => item.data === i.data)) {
                                console.log(i.value)
                                console.log(i.toBackend)
                                return {
                                    ...i,
                                    value: i.toBackend
                                }
                            }
                            return {...i}
                        })
                    };
                }
                return item;
            }));
    
            setUnsavedChanges({});
        } catch (error) {
            console.log(error);
            toast.error("Ошибка при сохранении изменений");
        }
    };
    
    


    async function getSettings() {
        try {
            const res = await appAPI.getSettings();
            if (res.error) {
                return toast.error(res.error);
            } else {
                if (!Array.isArray(res.settings)) {
                    throw new Error("Invalid settings format");
                }
    
                for (let i = 0; i < res.settings.length; i++) {
                    const setting = res.settings[i];
                    if (!setting) continue;
    
                    navigation.find(item => {
                        if (item.key_datas.includes(setting.data)) {
                            item.dataForRender.push({
                                title: setting.text ? setting.text : setting.data,
                                text: setting.text,
                                id: setting.id,
                                data: setting.data,
                                fromBackend: setting.data,
                                toBackend: setting.data,
                                type: "input",
                                value: setting.pkey
                            });
                        }
                    });
                }
                setServerSettings(res.settings);
            }
        } catch (e) {
            console.log(e);
            setNavigation(false);
            return toast.error("Ошибка при получении данных");
        }
    }
    
    
      useEffect(() => {
        getSettings()
      },[])

    const [unsavedChanges, setUnsavedChanges] = useState([]);

    const handleChangeString = (e, renderItem, index) => {
        const updatedValue = e.target.checked ? "1" : "0";
    
        setUnsavedChanges(prev => {
            // Ensure prev is an array
            const prevArray = Array.isArray(prev) ? prev : [];
            const itemIndex = prevArray.findIndex(i => i.data === renderItem.data);
    
            // Update if item exists, otherwise add new item
            const updatedChanges = itemIndex > -1
                ? prevArray.map(i => i.data === renderItem.data ? { ...i, pkey: updatePkey(i.pkey, index, updatedValue) } : i)
                : [...prevArray, { data: renderItem.data, pkey: updatePkey(renderItem.value, index, updatedValue), text: renderItem.text, id: renderItem.id }];
    

                setNavigation(prev => prev.map(item => {
                    if (item.key_datas.includes(renderItem.data)) {
                        const updatedDataForRender = item.dataForRender.map(i => {
                            if (i.data === renderItem.data) {
                                const updatedChange = updatedChanges.find(change => change.data === i.data);
                                const newValue = updatePkey(updatedChange.pkey, index, updatedValue);
                                return { ...i, toBackend: newValue };
                            }
                            return i;
                        });
                        return { ...item, dataForRender: updatedDataForRender };
                    }
                    return item;
                }))
            console.log("Updated Changes:", updatedChanges); // Debugging line
            return updatedChanges;
        });

        
    
        /*setNavigation(prev => prev.map(item => {
            if (item.key_datas.includes(renderItem.data)) {
                const updatedDataForRender = item.dataForRender.map(i => {
                    if (i.data === renderItem.data) {
                        const newValue = updatePkey(i.value, index, updatedValue);
                        return { ...i, toBackend: newValue };
                    }
                    return i;
                });
                return { ...item, dataForRender: updatedDataForRender };
            }
            return item;
        }));*/
    };
    
    const updatePkey = (pkey, index, updatedValue) => {
        const array = pkey.split('');
        array[index] = updatedValue;
        console.log(array.join(''))
        return array.join('');
    };
    
    
    
    


    const handleChange = (e, renderItem) => {
        const updatedValue = e.target.value;
    
        setUnsavedChanges(prev => {
            // Ensure prev is an array
            const prevArray = Array.isArray(prev) ? prev : [];
    
            const index = prevArray.findIndex(i => i.data === renderItem.data);
    
            // Update if item exists, otherwise add new item
            const updatedChanges = index > -1
                ? prevArray.map(i => i.data === renderItem.data ? { ...i, pkey: updatedValue } : i)
                : [...prevArray, { data: renderItem.data, pkey: updatedValue, text: renderItem.text, id: renderItem.id }];
    
            console.log("Updated Changes:", updatedChanges); // Debugging line
            return updatedChanges;
        });
    
        setNavigation(prev => prev.map(item => {
            if (item.key_datas.includes(renderItem.data)) {
                const updatedDataForRender = item.dataForRender.map(i => {
                    if (i.data === renderItem.data) {
                        return { ...i, toBackend: updatedValue };
                    }
                    return i;
                });
                return { ...item, dataForRender: updatedDataForRender };
            }
            return item;
        }));
    };
    
    

    if (userInfo === false) return <Navigate to="/auth" />;
    if (serverSettings === null) return <Loader />;

    console.log(unsavedChanges)
    return (
        <>
            <Layout section="Настройки">
            <div className="m-1">
                <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-50 lg:hidden " onClose={setSidebarOpen}>
                        <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <div className="fixed inset-0 bg-gray-900/80" />
                        </Transition.Child>

                        <div className="fixed inset-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                                    <span className="sr-only">Close sidebar</span>
                                    <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                </button>
                                </div>
                            </Transition.Child>
                           


                            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-indigo-600 px-6 py-2 ">
                            <div className="flex mt-4 text-lg text-white shrink-0 items-center">
                               Разделы настроек
                                </div>
                                <nav className="flex flex-1 flex-col">
                                <ul role="list" className="flex flex-1 flex-col gap-y-7">
                                    <li>
                                    <ul role="list" className="-mx-2 space-y-1">
                                        {navigation.map((item) => (
                                        <li key={item.name}>
                                            <div
                                            onClick={() => setActiveSection(item)}
                                            className={classNames(
                                                item.current
                                                ? 'bg-indigo-700 text-white'
                                                : 'text-indigo-200 hover:text-white hover:bg-indigo-700',
                                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                            )}
                                            >
                                            {item.name}
                                            </div>
                                        </li>
                                        ))}
                                    </ul>
                                    </li>
                                </ul>
                                </nav>
                            </div>
                            </Dialog.Panel>
                        </Transition.Child>
                        </div>
                    </Dialog>
                    </Transition.Root>


                    <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-indigo-600 p-6 py-3 m-3">
                        <nav className="flex flex-1 flex-col">
                        <ul role="list" className="flex flex-1 flex-col gap-y-7">
                            <li>
                            <ul role="list" className="-mx-2 space-y-1">
                                {navigation.map((item) => (
                                <li key={item.name}>
                                    <div
                                    onClick={() => setActiveSection(item)}
                                    className={classNames(
                                        item.current
                                        ? 'bg-indigo-700 text-white'
                                        : 'text-indigo-200 hover:text-white hover:bg-indigo-700',
                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                    )}
                                    >
                                    {item.name}
                                    </div>
                                </li>
                                ))}
                            </ul>
                            </li>
                        </ul>
                        </nav>
                    </div>
                    </div>

                    <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-indigo-600 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
                    <button type="button" className="-m-2.5 p-2.5 text-indigo-200 lg:hidden" onClick={() => setSidebarOpen(true)}>
                        <span className="sr-only">Open sidebar</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                    <div className="flex-1 text-sm font-semibold leading-6 text-white">{activeSection?.name}</div>
                    </div>

                    <main className="py-0 lg:pl-72">
                        {loading === true && !serverSettings ? <div className='w-full h-32 flex items-center justify-center'>
                            <img src="/mix.png" className='h-16'/>
                        </div> : loading === false && serverSettings ? <div>
                            {navigation.map(nav => {
                                if (nav.name === 'Модули бота' && activeSection.name === 'Модули бота') {
                                    return (
                                        <>
                                            {nav.dataForRender && nav.dataForRender.length > 0 && nav.dataForRender.map(renderItem => {
                                                if (renderItem.type === "input") {
                                                    const array = renderItem.value ? renderItem.value.split("") : [];
                                                    return (
                                                        <div key={renderItem.id} className='my-2'>
                                                                {array.map((num, index) => {
                                                                    return (
                                                                    <div key={index} className="mt-2">
                                                                        <label className="flex justify-between items-center text-l font-medium leading-6 text-gray-900">
                                                                            {index === 0 ? "Купить USDT" : index === 1 ? "Продать USDT" : index === 2 ? "Карта PYYPL" : index === 3 ? "Пополнить" 
                                                                            : index === 4 ? "Вывести" : index === 5 ? "Наличные" : index === 6 ? "Обмен Валюты" 
                                                                            : index === 7 ? "Scan PAY" : index === 8 ? "USDT (BEP20)" : index === 9 ? "BTC (BTC)" : "ETH (ERC20)"}
                                                                        
                                                                
                                                                        <label className="swiper-checkbox">
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={unsavedChanges.length > 0 ? (unsavedChanges.find(i => i.data === renderItem.data)?.pkey.split("")[index]) === '1' : num === '1'}
                                                                                onChange={(e) => handleChangeString(e, renderItem, index)}
                                                                                className="h-8 w-8 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                                            />
                                                                                <span className="slider"></span>
                                                                            </label>
                                                                        </label>
                                                                    </div>
                                                                )})}
                                                        </div>
                                                    )
                                                }
                                            })}

                                {unsavedChanges.length > 0 && <button
                                    type="button"
                                    className="mt-2 w-full h-12 rounded bg-indigo-600 px-2 py-1 text-xx font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    onClick={handleSaveChanges}
                                >
                                    Сохранить
                                </button> }
                                        </>
                                    )
                                }



                                if (nav.name === 'Наличка банки' && activeSection.name === 'Наличка банки') {
                                    return (
                                        <>
                                            {nav.dataForRender && nav.dataForRender.length > 0 && nav.dataForRender.map(renderItem => {
                                                if (renderItem.type === "input") {
                                                    const array = renderItem.value ? renderItem.value.split("") : [];
                                                    return (
                                                        <div key={renderItem.id} className='my-2'>
                                                                {array.map((num, index) => {
                                                                    return (
                                                                    <div key={index} className="mt-2">
                                                                        <label className="flex justify-between items-center text-l font-medium leading-6 text-gray-900">
                                                                            {index === 0 ? "BANGKOK BANK" : index === 1 ? "KRUNGSRI BANK" : index === 2 ? "KASIKORN BANK" : index === 3 ? "SIAM SCB BANK" 
                                                                            : index === 4 ? "KRUNGTHAI BANK" : index === 5 ? "KBZ BANK" : index === 6 ? "CB BANK" 
                                                                            : index === 7 ? "ZIRAAT BANK" : ""}
                                                                        
                                                                

                                                                        <label className="swiper-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={unsavedChanges.length > 0 ? (unsavedChanges.find(i => i.data === renderItem.data)?.pkey.split("")[index]) === '1' : num === '1'}
                                                                            onChange={(e) => handleChangeString(e, renderItem, index)}
                                                                            className="h-8 w-8 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                                        />
                                                                            <span className="slider"></span>
                                                                        </label>
                                                                        
                                                                        </label>
                                                                    </div>
                                                                )})}
                                                        </div>
                                                    )
                                                }
                                            })}

                                            {unsavedChanges.length > 0 && <button
                                                type="button"
                                                className="mt-2 w-full h-12 rounded bg-indigo-600 px-2 py-1 text-xx font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                onClick={handleSaveChanges}
                                            >
                                                Сохранить
                                            </button> }
                                        </>
                                    )
                                }
                                if(nav.current === true) {
                                    return (
                                        <>
                                            {nav.dataForRender && nav.dataForRender.length > 0 && nav.dataForRender.map(renderItem => {
                                                if(renderItem.type === "input") {
                                                    return (
                                                        <div className='my-2' key={renderItem.data}>
    <label htmlFor={renderItem.title} className="block text-sm font-medium leading-6 text-gray-900">
        {renderItem.title}
    </label>
    <div className="mt-2">
        <input
            type="text"
            name={renderItem.title}
            value={
                unsavedChanges.length > 0
                ? unsavedChanges.find(i => i.data === renderItem.data)?.pkey ?? renderItem.value
                : renderItem.value
            }
            onChange={(e) => handleChange(e, renderItem)}
            id={renderItem.title}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
    </div>
</div>

                                                    )
                                                }
                                                if(renderItem.type === "textarea") {
                                                    return (
                                                        <div className='my-2' >
                                                            <label htmlFor={renderItem.title}  className="block text-sm font-medium leading-6 text-gray-900">
                                                                {renderItem.title} 
                                                            </label>
                                                            <div className="mt-2">
                                                                <textarea
                                                                rows={4}
                                                                name="comment"
                                                                id={renderItem.title} 
                                                                className="resize-none	block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                defaultValue={''}
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })}
                                            {unsavedChanges.length > 0 && <button
                                    type="button"
                                    className="mt-2 w-full h-12 rounded bg-indigo-600 px-2 py-1 text-xx font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    onClick={handleSaveChanges}
                                >
                                    Сохранить
                                </button> }
                                        </>
                                    )
                                }

                                
                            })}
                        </div>: <div className='w-full h-32 flex items-center justify-center text-lg'>Ничего не найдено</div>}
                    </main>
                </div>
            </Layout>
        </>
    )
}

export default Settings