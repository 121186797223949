const baseURL = "https://mixpayadm.easycrypto.space/api/api.php"

export const appAPI = {
    async getUser() {
        try {
            const res = await fetch(`${baseURL}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("ADMINMIXPAY__SERVICE__JWT")}`
                },
                body: JSON.stringify({ "action": "getuser", 'token': localStorage.getItem("ADMINMIXPAY__SERVICE__JWT") })
            }).then(res => res.json())
            console.log(res)
            return res
        } catch (e) {
            console.log(e)
        }
    },
    async auth(data) {
        try {
            const res = await fetch(`${baseURL}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            }).then(res => res.json())
            return res
        } catch (e) {
            console.log(e)
        }
    },
    async getOrders(page = 1,limit = 50) {
        const res = await fetch(`${baseURL}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("ADMINMIXPAY__SERVICE__JWT")}`
            },
            body: JSON.stringify({
                "action": "alloperations",
                "size": limit,
                "page": page
              })
        }).then(res => res.json())
        return res
    },
    async getUsers(page = 1,limit = 50) {
        const res = await fetch(`${baseURL}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("ADMINMIXPAY__SERVICE__JWT")}`
            },
            body: JSON.stringify({
                "action": "allusers",
                "size": limit,
                "page": page
              })
        }).then(res => res.json())
        return res
    },
    async getSettings(data) {
        const res = await fetch(`${baseURL}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("ADMINMIXPAY__SERVICE__JWT")}`
            },
            body: JSON.stringify({"action": "allsettings"})
        }).then(res => res.json())
        return res
    },
    async saveSettings(data) {
        const res = await fetch(`${baseURL}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("ADMINMIXPAY__SERVICE__JWT")}`
            },
            body: JSON.stringify(data)
        }).then(res => res.json())
        return res
    },
    async updateOrder(data) {
        const res = await fetch(`${baseURL}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("ADMINMIXPAY__SERVICE__JWT")}`
            },
            body: JSON.stringify(data)
        }).then(res => res.json())
        return res
    },
    async editComment(data) {
        const res = await fetch(`${baseURL}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("ADMINMIXPAY__SERVICE__JWT")}`
            },
            body: JSON.stringify(data)
        }).then(res => res.json())
        return res
    },
    async getComment(data) {
        const res = await fetch(`${baseURL}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("ADMINMIXPAY__SERVICE__JWT")}`
            },
            body: JSON.stringify(data)
        }).then(res => res.json())
        return res
    },
    async getMediaFiles(data) {
        const res = await fetch(`${baseURL}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("ADMINMIXPAY__SERVICE__JWT")}`
            },
            body: JSON.stringify(data)
        }).then(res => res.json())
        return res
    },
}