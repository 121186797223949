import { useState } from "react";
import toast from "react-hot-toast";
import { Navigate, useNavigate } from "react-router-dom";
import { appAPI } from "../api/service";

const Auth = ({userInfo, getUser}) => {
    const nav = useNavigate();
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async () => {
    if (!name.trim() || !password.trim()) return toast.error("Введите все данные");
    const res = await appAPI.auth({
        "action": "authenticate",
        "login": name,
        "password": password
    })
    console.log(res)
    if (res?.error) {
        return toast.error(res.error);
    } else {
        if(!res) return toast.error("Error")
        await localStorage.setItem("ADMINMIXPAY__SERVICE__JWT", res.token)
        toast.success("Успешный вход в аккаунт");
        await getUser()
        return nav("/");
    }
  };

  if(userInfo !== false && userInfo !== null) return <Navigate to="/" />
    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img
                    className="mx-auto h-16 w-auto"
                    src="/mix.png"
                    alt="Your Company"
                />
                <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Авторизация в аккаунт
                </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                    <div className="space-y-6" action="#" method="POST">
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                        Логин
                        </label>
                        <div className="mt-2">
                        <input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                            id="email"
                            name="email"
                            type="text"
                            autoComplete="email"
                            required
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        </div>
                    </div>

                    <div>
                        <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                        Пароль
                        </label>
                        <div className="mt-2">
                        <input
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            required
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        </div>
                    </div>

                    <div>
                        <button
                        onClick={() => handleSubmit()}
                        type="submit"
                        className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                        Авторизоваться
                        </button>
                    </div>
                    </div>

                    <div>
                </div>
                </div>
                </div>
            </div>
        </>
    )
}

export default Auth