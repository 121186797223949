import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Layout from '../layouts/Layout';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Navigate } from 'react-router-dom';
import Loader from './../componenets/loader';
import { appAPI } from '../api/service';
import toast from 'react-hot-toast';
import { format } from 'date-fns';
import { OrderModal } from '../componenets/order';


export default function Main({ userInfo }) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [totalPages, setTotalPages] = useState(null);
  const [pageSet, setPageSet] = useState(0);

  const [transactions, setTransactions] = useState([]);

  const [openOrder, setOpenOrder] = useState(null);
  const [modalActive, setModalActive] = useState(null);

  const [loading, setLoading] = useState(false)

  

  async function getOrders(auto = false) {
    try {
      if(auto !== true) setLoading(true)
        const res = await appAPI.getOrders(page, limit);
        if (res.error) return toast.error(res.error);
        setTotalPages(res.total_pages);
        setTransactions(res.transactions);
    } finally {
      setLoading(false)
    }
    
  }


  

  const updateOrder = async (action) => {
    if(openOrder.type == "0" && openOrder.finish === "0") return toast.error("Транзакция в процессe")
    let newType;
    let newFinish = "1"

    switch (action) {
      case "finish": {
          // Если finish, то тип заявки будет успешный
          switch (openOrder.type) {
              case "0":
                  newType = "0";  // Заморожено
                  newFinish = "2"
                  break;
              case "1":
                  newType = "1";  // Покупка (Успешно)
                  break;
              case "2":
                  newType = "2";  // Продажа (Успешно)
                  break;
              case "3":
                  newType = "3";  // Обмен (Успешно)
                  break;
              case "4":
                  newType = "4";  // Наличные (Успешно)
                  break;
              case "5":
                  newType = "5";  // Scan PAY (Успешно)
                  break;
              case "6":
                  newType = "6";  // 💳 Карта PYYPL (Пополнение) (Успешно)
                  break;
              case "7":
                  newType = "7";  // 💳 Карта PYYPL (Вывод) (Успешно)
                  break;
              case "9":
                  newType = "9";  // Scan PAY Трейдеры (Успешно)
                  break;
              case "10":
                  newType = "10"; // Наличные Трейдеры (Успешно)
                  break;
              default:
                  newType = "0";
                  break;
          }
          break;
      }
      case "pending": {
          // Если pending, то заявка будет заморожена
          switch (openOrder.type) {
              default:
                newType = openOrder.type
                newFinish = "2"
                break;
          }
          break;
      }
      case "cancel": {
          // Если cancel, то тип заявки будет отменен
          switch (openOrder.type) {
              case "0":
                  newType = "0";
                  break;
              case "1":
                  newType = "-1"; // Покупка (Отмена)
                  break;
              case "2":
                  newType = "-2"; // Продажа (Отмена)
                  break;
              case "3":
                  newType = "-3"; // Обмен (Отмена)
                  break;
              case "4":
                  newType = "-4"; // Наличные (Отмена)
                  break;
              case "5":
                  newType = "-5"; // Scan PAY (Отмена)
                  break;
              case "6":
                  newType = "-6"; // 💳 Карта PYYPL (Пополнение) (Отмена)
                  break;
              case "7":
                  newType = "-7"; // 💳 Карта PYYPL (Вывод) (Отмена)
                  break;
              case "9":
                  newType = "-9"; // Scan PAY Трейдеры (Отмена)
                  break;
              case "10":
                  newType = "-10"; // Наличные Трейдеры (Отмена)
                  break;
              default:
                  newType = "0";
                  break;
          }
          break;
      }
      default: {
          newType = ""; 
          break;
      }
  }
    const res = await appAPI.updateOrder({
      "action": "edittransaction",
      "transaction": {
        "id": openOrder.id,
        "type": newType,
        "finish": newFinish
      }
    })
    if (res.error) return toast.error(res.error);
    setOpenOrder({...openOrder, finish: newFinish, type: newType})
    setTransactions(transactions.map(trans => {
      if(trans.id === openOrder.id) {
        return {...trans, finish: newFinish, type: newType}
      }
      return trans
    }))
    return toast.success(res.message)
  }

  useEffect(() => {
    const timer = setInterval(() => {
     // getOrders(true)
    }, 30000)

    getOrders();

    return () => {
      clearInterval(timer)
    }
  }, [page]);

  const handleStartDateChange = (date) => {
    setStartDate(date ? Math.floor(date.getTime() / 1000) : null);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date ? Math.floor(date.getTime() / 1000) : null);
  };


  const [image, setImage] = useState("")

  if (userInfo === false) return <Navigate to="/auth" />;
  if (transactions === null || totalPages === null) return <Loader />;

  const handlePrevPageSet = () => {
    if (pageSet > 0) setPageSet(pageSet - 1);
  };

  const handleNextPageSet = () => {
    if ((pageSet + 1) * 5 < totalPages) setPageSet(pageSet + 1);
  };

  const renderPageButtons = () => {
    const startPage = pageSet * 5 + 1;
    const endPage = Math.min((pageSet + 1) * 5, totalPages);
    const pageButtons = [];

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
            page === i ? 'bg-indigo-600 text-white' : 'text-gray-900'
          } ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0`}
          onClick={() => setPage(i)}
        >
          {i}
        </button>
      );
    }
    return pageButtons;
  };

  const getStatus = (finish, type) => {
    type = String(type).replace("-", ""); // Приведение type к строке
    switch (type) {
      case "0": return 'В процессe оплаты';
      case "1": return 'Крипта';
      case "2": return 'Крипта';
      case "3": return 'Обмен';
      case "4": return 'Наличные';
      case "5": return 'Scan PAY';
      case "6": return '💳 Карта PYYPL (Пополнение)';
      case "7": return '💳 Карта PYYPL (Вывод)';
      case "9": return 'Scan PAY Трейдеры';
      case "10": return 'Наличные Трейдеры';
      default: return 'Неизвестный тип';
  }
/*
    if (finish == 0) {
        switch (type) {
            case "0": return 'В процессe оплаты';
            case "1": return 'Покупка крипты';
            case "2": return 'Продажа крипты';
            case "3": return 'Обмен';
            case "4": return 'Наличные';
            case "5": return 'Scan PAY';
            case "6": return '💳 Карта PYYPL (Пополнение)';
            case "7": return '💳 Карта PYYPL (Вывод)';
            case "9": return 'Scan PAY Трейдеры';
            case "10": return 'Наличные Трейдеры';
            default: return 'Неизвестный тип';
        }
    }

    // Если заявка закрыта
    if (finish == 1) {
        switch (type) {
            case "0": return '---';
            case "1": return 'Покупка (Успешно)';
            case "2": return 'Продажа (Успешно)';
            case "3": return 'Обмен (Успешно)';
            case "4": return 'Наличные (Успешно)';
            case "5": return 'Scan PAY (Успешно)';
            case "6": return '💳 Карта PYYPL (Пополнение) (Успешно)';
            case "7": return '💳 Карта PYYPL (Вывод) (Успешно)';
            case "9": return 'Scan PAY Трейдеры (Успешно)';
            case "10": return 'Наличные Трейдеры (Успешно)';
            case "-1": return 'Покупка (Отмена)';
            case "-2": return 'Продажа (Отмена)';
            case "-3": return 'Обмен (Отмена)';
            case "-4": return 'Наличные (Отмена)';
            case "-5": return 'Scan PAY (Отмена)';
            case "-6": return '💳 Карта PYYPL (Пополнение) (Отмена)';
            case "-7": return '💳 Карта PYYPL (Вывод) (Отмена)';
            case "-9": return 'Scan PAY Трейдеры (Отмена)';
            case "-10": return 'Наличные Трейдеры (Отмена)';
            default: return 'Неизвестный тип';
        }
    }

    

    if (finish == 2) {
        return 'Заморожена';
    }*/
    return 'Неизвестный статус';
};


const getStatusFinish = (finish, type) => {
  type = String(type); // Приведение type к строке

  if (finish == 0) {
    return "Ожидает"
  }

  // Если заявка закрыта
  if (finish == 1) {
      switch (type) {
          case String(type).includes("-"): return 'Завершена (отмена)';
          case String(type): return 'Завершена (успешно)';
          default: return 'Неизвестный тип';
      }
  }

  

  if (finish == 2) {
      return 'Ожидает - заморожена';
  }
  return 'Неизвестный статус';
};






  console.log(openOrder)
  
  return (
    <>
    {image && (
      <>
        <div style={{zIndex:"100000000"}} onClick={() => setImage("")} className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <img src={image} alt="Preview" className="max-w-full max-h-full" onClick={(e) => e.stopPropagation()} />
        </div>
          <div
            type="button"
            style={{top:"10px",zIndex:"10000000000", right:"10px", borderRadius:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}
            className=" h-10 w-10 absolute  bg-white px-3 py-2 text-2xl  font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={() => setImage("")}
          >
              ❌
        </div>
      </>
      
    )}

      <Transition.Root show={modalActive} as={Fragment} className="openModalBackground">
        <div as="div" className="relative z-10" style={{ zIndex: '1000' }}>
          <div className="fixed overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <OrderModal openOrder={openOrder} updateOrder={updateOrder} getStatus={getStatus} getStatusFinish={getStatusFinish} setImage={setImage} setModalActive={setModalActive} setOpenOrder={setOpenOrder}/>
                    </div>
                </div>
                </Transition.Child>
                </div>
              </div>
            </div>
          </div>
      </Transition.Root>



      <Layout section="Сделки">
        <div className="px-3 lg:px-4">
          <div className="flex items-center">
            <div className="flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">Сделки</h1>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <button
                type="button"
                onClick={() => getOrders(page !== 1)}
                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Обновить сделки
              </button>
            </div>
          </div>
          <div>
            <div className="mt-2 flex overflow-x-auto">
              <DatePicker
                selected={startDate ? new Date(startDate * 1000) : null}
                onChange={handleStartDateChange}
                dateFormat="dd/MM/yyyy"
                className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholderText="Сделки с"
              />
              <DatePicker
                selected={endDate ? new Date(endDate * 1000) : null}
                onChange={handleEndDateChange}
                dateFormat="dd/MM/yyyy"
                className=" block ml-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholderText="Сделки по"
              />
              <input
                type="text"
                name="email"
                id="email"
                className="block ml-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Номер сделки"
              />
            </div>
          </div>
          <div className='sticky top-14 bg-white'>
            <div className="flex items-center justify-between bg-white pt-4 pb-2 ">
              <div className="flex flex-1 items-center justify-between">
                <div>
                  <p className="text-sm text-gray-700">
                    Найдено: {(page - 1) * transactions.length + 1} - {page  * transactions.length} 
                  </p>
                </div>
                <div>
                  <nav aria-label="Pagination" className="isolate inline-flex -space-x-px rounded-md shadow-sm">
                    <button
                      className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                      onClick={handlePrevPageSet}
                      disabled={pageSet === 0}
                    >
                      <span className="sr-only">Previous</span>
                      <ChevronLeftIcon aria-hidden="true" className="h-5 w-5" />
                    </button>
                    {renderPageButtons()}
                    <button
                      className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                      onClick={handleNextPageSet}
                      disabled={(pageSet + 1) * 5 >= totalPages}
                    >
                      <span className="sr-only">Next</span>
                      <ChevronRightIcon aria-hidden="true" className="h-5 w-5" />
                    </button>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle h-max">
                {loading === true ? <div className='flex align-center min-w-full justify-center mt-32 '>
                  <img src="/load.svg" className='w-16 h-16' />
                </div> : <>
                <table className="min-w-full divide-y divide-gray-300">
  <thead>
    <tr>
      <th
        scope="col"
        className="py-3.5 pl-4 pr-3 text-left text-sm font-bold text-gray-900 sm:pl-6 lg:pl-8 border-r border-gray-200"
      >
        №
      </th>
      <th scope="col" className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900 border-r border-gray-200">
        Пользователь
      </th>
      <th scope="col" className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900 border-r border-gray-200">
        Отдает
      </th>
      <th scope="col" className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900 border-r border-gray-200">
        Получает
      </th>
      <th scope="col" className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900 border-r border-gray-200">
        Курс обмена
      </th>
      <th scope="col" className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900 border-r border-gray-200">
        Время транзакции
      </th>
      <th scope="col" className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900 border-r border-gray-200">
        Тип обмена
      </th>
      <th scope="col" className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900 border-r border-gray-200">
        Статус транзакции
      </th>
    </tr>
  </thead>
  <tbody className="divide-y divide-gray-200 bg-white">
    {transactions.length > 0 && transactions.map((transaction) => (
      <tr key={transaction.id} className="hover:bg-gray-50" onClick={() => {
        setModalActive(true);
        setOpenOrder(transaction?.user_requisites?.length > 250 ? {...transaction, user_requisites: transaction.user_requisites.split("|!|") } : transaction);
      }}>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-l font-medium text-gray-900 sm:pl-6 lg:pl-8 border-r border-gray-200">
          {transaction.id}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 border-r border-gray-200">{transaction.chat_id}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 border-r border-gray-200">{transaction.qiwi_amount} {transaction.qiwi_number}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 border-r border-gray-200">{transaction.btc_amount} {transaction.btc_tx}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 border-r border-gray-200">{transaction.stable_kurs}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 border-r border-gray-200">
          {format(new Date(transaction.transaction_date * 1000), 'dd.MM.yyyy HH:mm')}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 border-r border-gray-200">{getStatus(transaction?.finish, transaction?.type)}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 border-r border-gray-200">{getStatusFinish(transaction?.finish, transaction?.type)}</td>
        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
          {/*<a href="#" className="text-indigo-600 hover:text-indigo-900">
            Edit<span className="sr-only">, {transaction.id}</span>
          </a>*/}
        </td>
      </tr>
    ))}
  </tbody>
</table>

                </>}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
