import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Layout from '../layouts/Layout';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Navigate } from 'react-router-dom';
import Loader from './../componenets/loader';
import { appAPI } from '../api/service';
import toast from 'react-hot-toast';
import { format } from 'date-fns';

export default function Users({ userInfo }) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [totalPages, setTotalPages] = useState(null);
  const [pageSet, setPageSet] = useState(0);
  const [users, setUsers] = useState([]);
  const [openOrder, setOpenOrder] = useState(null);

  async function getUsers(newUsers = false) {
    const res = await appAPI.getUsers(page, limit);
    console.log(res);
    if (res.error) return toast.error(res.error);

    if (newUsers) {
      setTotalPages(res.total_pages);
      setUsers(res.users);
    } else {
      setUsers((prev) => [...prev, ...res.users]);
    }
  }

  useEffect(() => {
    getUsers(true);
  }, [page]);

  const handleStartDateChange = (date) => {
    setStartDate(date ? Math.floor(date.getTime() / 1000) : null);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date ? Math.floor(date.getTime() / 1000) : null);
  };

  if (userInfo === false) return <Navigate to="/auth" />;
  if (users === null || totalPages === null) return <Loader />;

  const handlePrevPageSet = () => {
    if (pageSet > 0) setPageSet(pageSet - 1);
  };

  const handleNextPageSet = () => {
    if ((pageSet + 1) * 5 < totalPages) setPageSet(pageSet + 1);
  };

  const renderPageButtons = () => {
    const startPage = pageSet * 5 + 1;
    const endPage = Math.min((pageSet + 1) * 5, totalPages);
    const pageButtons = [];

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
            page === i ? 'bg-indigo-600 text-white' : 'text-gray-900'
          } ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0`}
          onClick={() => setPage(i)}
        >
          {i}
        </button>
      );
    }
    return pageButtons;
  };

  const getRole = (root) => {
    switch (root) {
      case 1:
        return "Админ";
      case 2:
        return "Модератор";
      default:
        return "Юзер";
    }
  };

  return (
    <>
      <Layout section="Пользователи">
        <div className="px-3 lg:px-4">
          <div className="flex items-center">
            <div className="flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">Пользователи</h1>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <button
                type="button"
                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Обновить пользователей
              </button>
            </div>
          </div>
          <div className="flex items-center justify-between bg-white pt-4 pb-2">
            <div className="flex flex-1 items-center justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Найдено: {(page - 1) * users.length} - {page * users.length}
                </p>
              </div>
              <div>
                <nav aria-label="Pagination" className="isolate inline-flex -space-x-px rounded-md shadow-sm">
                  <button
                    className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    onClick={handlePrevPageSet}
                    disabled={pageSet === 0}
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon aria-hidden="true" className="h-5 w-5" />
                  </button>
                  {renderPageButtons()}
                  <button
                    className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    onClick={handleNextPageSet}
                    disabled={(pageSet + 1) * 5 >= totalPages}
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon aria-hidden="true" className="h-5 w-5" />
                  </button>
                </nav>
              </div>
            </div>
          </div>
          <div className="mt-2 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                      >
                        ID
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Пользователь ID
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Username
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        First name
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Last name
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Реферал ID
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Количество рефералов
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Количество операций
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Бан
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Партнер скан пей
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Партнер наличных
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Баланс
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Роль
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Последние действия
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Дата регистрации
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {users.map((user) => (
                      <tr key={user.user_id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                          {user.id}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.chat_id}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.username}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.first_name}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.last_name}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.id_father}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.count_inv}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.count_operations}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.banned ? 'Да' : 'Нет'}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.partner_scan}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.partner_nal}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.balance}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{getRole(user.root)}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{format(new Date(user.time * 1000), 'dd.MM.yyyy HH:mm')}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{format(new Date(user.time_begin * 1000), 'dd.MM.yyyy HH:mm')}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
